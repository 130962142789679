import { QRCode } from "@progress/kendo-react-barcodes";
import { GridCustomCellProps } from "@progress/kendo-react-grid";
import React, { useEffect, useState } from "react";
import { generateLocUrl } from "./QrCodeUtils";

const QrCodeCell = (props: GridCustomCellProps) => {
    const [locUrl, setLocUrl] = useState<string>("");
    const [locId, setLocId] = useState<string>(props?.dataItem?.id);

    useEffect(() => {
        const url = generateLocUrl(locId)
        setLocUrl(url)
    }, [])

    return (
        <td style={{ textAlign: "center" }}>
            <QRCode
                value={locUrl}
                errorCorrection="H"
            />
            <div>
                <a href={locUrl} target="_blank">
                    {locId}
                </a>
            </div>
        </td>
    )
}

export default QrCodeCell