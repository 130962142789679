import * as React from 'react';
import './App.css';
import '@progress/kendo-theme-default/dist/all.css';
import FormArea from "./components/form/FormArea";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import PrivateRoute from "./components/auth/PrivateRoute";
import Management from "./components/form/Management";
import {MsalProvider} from "@azure/msal-react";
import {IPublicClientApplication} from "@azure/msal-browser";
import MyAppbar from "./components/appbar/Appbar";
import ReaderPage from "./components/form/ReaderPage";
import QrCodePage from './components/qrcode/QrCodePage';

function App({instance}: { instance: IPublicClientApplication }) {
    return (
        <MsalProvider instance={instance}>
            <BrowserRouter>
                <MyAppbar>
                    <div className="autosize-div">
                        <Routes>
                            <Route path="/" element={<FormArea/>}/>
                            <Route path="/management" element={<PrivateRoute/>}>
                                <Route index element={<Management/>}/>
                            </Route>
                            <Route path="/qrcodes" element={<PrivateRoute/>}>
                                <Route index element={<QrCodePage/>}/>
                            </Route>
                            <Route path="/qrreader" element={<ReaderPage/>}/>
                        </Routes>
                    </div>
                </MyAppbar>
            </BrowserRouter>
        </MsalProvider>
    );
}
export default App;
