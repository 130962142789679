import * as React from "react";
import {FieldRenderProps, FieldWrapper} from "@progress/kendo-react-form";
import {Label} from "@progress/kendo-react-labels";
import {Input, MaskedTextBox, TextArea} from "@progress/kendo-react-inputs";
import {isAdministrator} from "../auth/LoginLogic";
import {Upload} from "@progress/kendo-react-upload";
import {useEffect} from "react";
import i18n from "../tools/i18n";
import {IntlProvider, loadMessages, LocalizationProvider} from "@progress/kendo-react-intl";
import translationsDE from "../tools/translations/de-DE.json"
import translationsFR from "../tools/translations/fr-FR.json"
import translationsIT from "../tools/translations/it-IT.json"
import i18next from "i18next";


export const FormInput = (fieldRenderProps: FieldRenderProps) => {
    const {
        touched,
        label,
        id,
        hint,
        type,
        optional,
        disabled,
        validationMessage,
        ...others
    } = fieldRenderProps;
    const showValidationMessage: string | false | null = touched && validationMessage;
    const showHint: boolean = !showValidationMessage && hint;
    const hintId: string = showHint ? `${id}_hint` : "";
    const errorId: string = showValidationMessage ? `${id}_error` : "";
    return (
        <FieldWrapper>
            <Label
                editorId={id}
                optional={optional}
                editorDisabled={disabled}
                className="k-form-label"
            >
                {label}
            </Label>
            <div className={"k-form-field-wrap"}>
                <Input
                    id={id}
                    type={type}
                    disabled={disabled}
                    maxLength={100}
                    {...others}
                />
            </div>
        </FieldWrapper>

    )

}

export const FormTextArea = (fieldRenderProps: FieldRenderProps) => {
    const {
        touched,
        label,
        id,
        hint,
        type,
        optional,
        disabled,
        validationMessage,
        ...others
    } = fieldRenderProps;
    const showValidationMessage: string | false | null = touched && validationMessage;
    const showHint: boolean = !showValidationMessage && hint;
    const hintId: string = showHint ? `${id}_hint` : "";
    const errorId: string = showValidationMessage ? `${id}_error` : "";
    return (
        <FieldWrapper>
            <Label
                editorId={id}
                optional={optional}
                editorDisabled={disabled}
                className="k-form-label"
            >
                {label}
            </Label>
            <div className={"k-form-field-wrap"}>
                <TextArea
                    id={id}
                    disabled={disabled}
                    rows={4}
                    autoSize={true}
                    {...others}
                />
            </div>
        </FieldWrapper>
    )
}

export const FormMaskedInput = (fieldRenderProps: FieldRenderProps) => {
    const {
        validationMessage,
        touched,
        label,
        id,
        valid,
        hint,
        optional,
        disabled,
        ...others
    } = fieldRenderProps;
    const showValidationMessage: string | false | null = touched && validationMessage;
    const showHint: boolean = !showValidationMessage && hint;
    const hintId: string = showHint ? `${id}_hint` : "";
    const errorId: string = showValidationMessage ? `${id}_error` : "";
    return (
        <FieldWrapper>
            <Label
                editorId={id}
                editorDisabled={disabled}
                optional={optional}
                className={"k-form-label"}
            >
                {label}
            </Label>
            <div className={"k-form-field-wrap"}>
                <MaskedTextBox
                    id={id}
                    valid={valid}
                    ariaDescribedBy={`${hintId} ${errorId}`}
                    disabled={disabled}
                    readonly={!isAdministrator()}
                    {...others}
                />
            </div>
        </FieldWrapper>
    )
}

export const FormUpload = (fieldRenderProps: FieldRenderProps) => {

    const [locale, setLocale] = React.useState<string>("de-DE");

    useEffect(() => {
        const languageToLocaleMap = {
            'de': 'de-DE',
            'fr': 'fr-FR',
            'it': 'it-IT',
        };

        const currentLanguage = i18n.language;
        const newLocale = languageToLocaleMap[currentLanguage] || 'de-DE';

        setLocale(newLocale);
    }, [i18next.language])

    loadMessages(translationsDE, "de-DE")
    loadMessages(translationsFR, "fr-FR")
    loadMessages(translationsIT, "it-IT")


    const {
        value,
        id,
        optional,
        label,
        valid,
        hint,
        validationMessage,
        touched,
        disabled,
        ...others
    } = fieldRenderProps;

    const labelId: string = label ? `${id}_label` : "";
    const showValidationMessage: string | false | null = touched && validationMessage;
    const errorId: string = showValidationMessage ? `${id}_error` : "";
    const onChangeHandler = (event: any) => {
        fieldRenderProps.onChange({value: event.newState});
    };
    const onRemoveHandler = (event: any) => {
        fieldRenderProps.onChange({value: event.newState});
    };

    return (
        <FieldWrapper>
            <Label
                id={labelId}
                editorId={id}
                editorDisabled={disabled}
                optional={optional}
                className="k-form-label"
            >
                {label}
            </Label>
            <div className={"k-form-field-wrap"}>
                <LocalizationProvider language={locale}>
                    <IntlProvider locale={i18next.language}>
                        <Upload
                            id={id}
                            autoUpload={false}
                            showActionButtons={false}
                            multiple={true}
                            files={value}
                            onAdd={onChangeHandler}
                            onRemove={onRemoveHandler}
                            accept={"image/*"}
                            {...others}
                        />
                    </IntlProvider>
                </LocalizationProvider>
            </div>
        </FieldWrapper>
    );
};





