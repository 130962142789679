import * as React from 'react';
import Html5QrcodePlugin from "./QrReader";
import {useNavigate} from "react-router-dom";
import {LokIdValidator} from "./Validators";

const App: React.FC = () => {

    const navigate = useNavigate()
    const isValidURL = (str: string) => {
        try {
            new URL(str);
            return true;
        } catch (error) {
            return false;
        }
    };
    const handleQrCodeSuccess = (decodedText: string) => {
        
        if (isValidURL(decodedText)) {
            const url = new URL(decodedText)
            const urlParams = new URLSearchParams(url.search)
            const paramValue = urlParams.get("lokid")
            if(LokIdValidator(paramValue)){
                navigate(`/?lokid=${paramValue}`);
            }
            else{
                alert("Your QR code is invalid, please try again")
                
            }
            
            
        }
        
        // Check if the scanned content is a valid URL
       
    };
    // Define a callback function to handle QR code scanning errors
    const handleQrCodeError = (errorMessage: string) => {
        // console.error(errorMessage); // Log the error
    };

    return (
        <div className="App">
            <header className="App-header">
                <div className="qr-code-scanner">
                    <Html5QrcodePlugin
                        qrCodeSuccessCallback={handleQrCodeSuccess}
                        qrCodeErrorCallback={handleQrCodeError}
                    />
                </div>
            </header>
        </div>
    );
};

export default App;
