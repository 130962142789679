import * as React from "react";
import {
    AppBar,
    AppBarSection,
    AppBarSpacer,
} from "@progress/kendo-react-layout";
import "./AppbarDesign.css"
import DrawerField from "./Drawer";
import {useNavigate} from "react-router-dom";
import {Button} from "@progress/kendo-react-buttons";
import {useTranslation} from "react-i18next";
import i18n from '../tools/i18n'
import {DropDownList, DropDownListChangeEvent} from "@progress/kendo-react-dropdowns";

const MyAppbar = ({children}) => {
    const navigate = useNavigate()
    const handleHomeClick = () => {
        navigate("/")
    }

    const {t} = useTranslation();
    const handleChangeLanguage = (e: DropDownListChangeEvent) => {
        const lang = e.target.value.toUpperCase()
        localStorage.setItem("lang", lang)
        i18n.changeLanguage((localStorage.getItem("lang")||"DE").toLowerCase());
    }

    return (
        <React.Fragment>
            <AppBar>
                <AppBarSection className={"menu"}>
                    <DrawerField/>
                </AppBarSection>

                <AppBarSpacer className="spacer"/>

                <AppBarSection className="title">
                    <Button className={"title"} onClick={handleHomeClick}>{t("incRep")}</Button>
                </AppBarSection>

                <AppBarSpacer className="spacer"/>

                <AppBarSection>
                    <div>
                       <DropDownList
                           data={["DE", "FR", "IT"]}
                           defaultValue={localStorage.getItem("lang") || "DE"}
                           style={{width:"37px"}}
                           onChange={handleChangeLanguage}
                           
                       />
                        
                    </div>
                </AppBarSection>
            </AppBar>
            {children}
        </React.Fragment>
    );
};

export default MyAppbar;
