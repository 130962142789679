import i18n from "i18next";
export const NameValidator = (value:string) =>{
    if(!value)
        return i18n.t("errorName")
    else{
        if(!alphanumericRegex.test(value))
            return i18n.t("errorAlphabet")
        return "";
    }
}
export const DescriptionValidator = (value: string) =>
    !value ? i18n.t("errorDescription") : ""

const LokIdRegex: RegExp = new RegExp(/^(\d{2} \d{2} \d{4} \d{3}-\d{1})$/);
const alphanumericRegex = /^[a-zA-Z\söäüÖÄÜ]+$/;
export const LokIdValidator = (value) => {
    return LokIdRegex.test(value)
}
const checkFileSize = (uploadedFiles: any[],maxSize): boolean => {
    let totalSize = 0
    if (uploadedFiles == null)
        return false
    uploadedFiles.forEach(file => {
        totalSize = totalSize + file.size
    })
    console.log(totalSize)

    return totalSize > maxSize;

};
export const FileValidator = (files) => {
    return checkFileSize(files,  50 * 1024 * 1024) ? i18n.t("errorFile") : ""
}