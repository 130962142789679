import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import deTranslations from './translations/de.json';
import itTranslations from './translations/it.json';
import frTranslations from './translations/fr.json';

const defaultLanguage = (localStorage.getItem('lang') || 'DE').toLowerCase();

i18n
    .use(initReactI18next) 
    .init({
        resources: {
            de: {
                translation: deTranslations,
            },
            it: {
                translation: itTranslations, 
            },
            fr: {
                translation: frTranslations
            }
        },
        lng: defaultLanguage,
        fallbackLng: 'de',
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n; 