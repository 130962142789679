import { Grid, GridColumn, GridDataStateChangeEvent, GridToolbar } from '@progress/kendo-react-grid';
import React, { useState } from 'react';
import { Locomotive } from '../../models/Locomotive';
import { useTranslation } from 'react-i18next';
import QrCodeCell from './QrCodeCell';
import { process, State } from "@progress/kendo-data-query";
import { Button } from '@progress/kendo-react-buttons';
import QrCodePDFExport from './QrCodePDFExport';
import { PDFExport } from '@progress/kendo-react-pdf';

export interface QrCodeGridProps {
    locomotives: Locomotive[]
}

const initialGridState: State = {
    skip: 0,
    filter: {
        filters: [],
        logic: "or"
    },
    sort: [],
    group: [],
}

const QrCodeGrid = (props: QrCodeGridProps) => {
    const { t } = useTranslation()
    const [dataState, setDataState] = useState<State>(initialGridState)

    const onDataStateChange = (event: GridDataStateChangeEvent) => {
        console.log(event)
        setDataState(event.dataState);
    };

    const pdfExportComponent = React.useRef<PDFExport>(null);

    const exportPDFWithComponent = () => {
        if (pdfExportComponent.current) {
            pdfExportComponent.current.save();
        }
    };

    return (
        <div>
            <Grid
                data={process(props.locomotives, dataState)}
                filterable={true}
                onDataStateChange={onDataStateChange}
                {...dataState}
            >
                <GridToolbar>
                    <Button onClick={exportPDFWithComponent}>Export PDF</Button>
                </GridToolbar>
                <GridColumn
                    field='id'
                    title={t("qrCode")}
                    cell={QrCodeCell}
                    filterable={false}
                />
                <GridColumn
                    filterable={true}
                    field='name'
                    title={t("locomotiveName")}
                />
            </Grid>
            <QrCodePDFExport locomotives={props.locomotives} reference={pdfExportComponent}/>
        </div>
    );
}

export default QrCodeGrid