import { LogLevel, BrowserCacheLocation } from '@azure/msal-browser';
import { Configuration } from "@azure/msal-browser";
export const msalConfig:Configuration  = {

    auth: {
        clientId: '34a4fbce-ea04-4816-bdfd-dc3952f28c1c',
        authority: 'https://login.microsoftonline.com/ad96ab3f-3d64-4455-8acb-2a4f7badc27f',
        redirectUri: window.location.origin,
    },
    cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
        storeAuthStateInCookie: true,
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            },
        },
    },
};
export const loginRequest = {
    scopes: ['openid', 'profile', 'User.Read', 'User.ReadBasic.All'],
};