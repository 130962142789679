import * as React from "react";
import {Grid, GridColumn} from "@progress/kendo-react-grid";

const Management = () => {
    return (
        <Grid
            style={{
                height: "400px",
            }}>
            <GridColumn field={"Receiver Name"}/>
            <GridColumn field={"Email"}/>
            <GridColumn field={"active"}/>

        </Grid>


    );

}

export default Management