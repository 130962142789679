import {IPublicClientApplication, PublicClientApplication} from '@azure/msal-browser';
import {loginRequest, msalConfig} from './authConfig';
import jwtDecode from "jwt-decode";

const msalInstance = new PublicClientApplication(msalConfig);

interface DecodedToken {
    roles: string[];
}

const isAdministrator = () => {
    const account = msalInstance.getAllAccounts()[0]
    let idToken
    idToken = account?.idToken

    if (idToken) {
        localStorage.setItem('idToken', idToken);
    }

    idToken = localStorage.getItem('idToken');

    if (!idToken) return false;

    try {
        const decodedToken: DecodedToken = jwtDecode(idToken);

        if (decodedToken && Array.isArray(decodedToken.roles) && decodedToken.roles.length > 0) {
            const userRole = decodedToken.roles[0];
            return userRole.includes("Management");
        }
    } catch (error) {
        console.error("Error decoding token:", error);
    }

    return false;
};

function handleLogin(instance: IPublicClientApplication) {
    instance.loginRedirect(loginRequest)
        .then(() => {
            return instance.handleRedirectPromise();
        })
        .catch((error) => {
            console.error("Login failed:", error);
        });

}

function handleLogout(instance: IPublicClientApplication) {
    const logoutRequest = {
        mainWindowRedirectUri: window.location.origin,
        postLogoutRedirectUri: window.location.origin,
    };
    instance.logoutRedirect(logoutRequest);
    localStorage.removeItem("idToken");
}


export {isAdministrator, handleLogin, handleLogout, msalInstance};