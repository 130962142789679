import * as React from "react";
import {Drawer, DrawerContent, DrawerSelectEvent} from "@progress/kendo-react-layout";
import {handleLogin, handleLogout, isAdministrator} from "../auth/LoginLogic";
import {useMsal} from "@azure/msal-react";
import '@progress/kendo-theme-default/dist/all.css';
import {useNavigate} from "react-router-dom";
import i18n from "i18next";


const DrawerField = () => {

    const items = [
        {
            text: i18n.t("homeButton"),
            icon: "k-i-home",
            index: 0
        },
        {
            text: i18n.t("contact"),
            icon: "k-i-inbox",
            index: 1,
            disabled: true
        },
        {
            separator: true,
        },
        {
            text: i18n.t("management"),
            icon: "k-i-settings",
            disabled: true,
            index: 4
        },
        {
            text: i18n.t("codes"),
            icon: "k-i-qr-code",
            disabled: !isAdministrator(),
            index: 5
        },
        {
            separator: true,
        },
        {
            text: i18n.t("login"),
            icon: "k-i-login",
            disabled: isAdministrator(),
            index: 2
        },
        {
            text: i18n.t("logout"),
            icon: "k-i-logout",
            disabled: !isAdministrator(),
            index: 3
        },

    ];
    const [expanded, setExpanded] = React.useState(false);
    const mode = true

    let expandMode: "overlay" | "push" | undefined = mode ? "overlay" : "push";

    const {instance} = useMsal()

    const navigate = useNavigate()
    const handleClick = () => {
        setExpanded((prevState) => !prevState);
    };
    const handleSelect = (event: DrawerSelectEvent) => {
        switch (event.itemIndex) {
            case 0:
                navigate("/");
                break;
            case 1:
                navigate("/");
                break;
            case 2:
                handleLogin(instance);

                break;
            case 3:
                handleLogout(instance);
                break;
            case 4:
                navigate("/management");
                break;
            case 5:
                navigate("/qrcodes");
                break;
            default:
                break;
        }

        setExpanded(false);
    };
    return (
        <div>
            <Drawer
                expanded={expanded}
                position={"start"}
                mode={expandMode}
                animation={{
                    duration: 400,
                }}
                items={items.map((item, index) => ({
                    ...item,
                }))}
                onOverlayClick={handleClick}
                onSelect={handleSelect}
            >
                <DrawerContent>
                    <div className="k-form">
                        <div className="k-form-field">
                            <button
                                className="k-button k-button-md k-rounded-md k-button-flat k-button-flat-base"
                                onClick={handleClick}
                            >
                                <span className="k-icon k-i-menu"/>
                            </button>
                        </div>
                    </div>
                </DrawerContent>
            </Drawer>
        </div>
    );
};

export default DrawerField;