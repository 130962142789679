import { isAdministrator } from "./LoginLogic";
import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

const PrivateRoute = () => {
    const isAdmin = isAdministrator();
    const navigate = useNavigate();

    useEffect(() => {
        if (!isAdmin) {
            navigate("/");
        }
    }, [isAdmin, navigate]);

    return isAdmin ? <Outlet /> : null;
};

export default PrivateRoute;
