import axios from "axios";

const API_BASE_URL: string | undefined = process.env.REACT_APP_API_URL

export const getApiService = async () => {
  if(!API_BASE_URL){
    throw new Error("Cannot get API_URL")
  }

  return axios.create({
    baseURL: API_BASE_URL,
  })
}

