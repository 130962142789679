import * as React from 'react';
import './index.css';
import App from './App';
import {createRoot} from "react-dom/client";
import * as serviceWorker from "../src/serviceWorkerRegistration"
import {msalInstance} from "./components/auth/LoginLogic";

const rootElement = document.getElementById("root")
if(!rootElement) throw new Error("Failed to find the root element")
const root = createRoot(rootElement)
root.render(
    <App  instance={msalInstance}/>
);

serviceWorker.unregister()

