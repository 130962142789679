import React from "react";
import { Locomotive } from "../../models/Locomotive";
import { PDFExport } from "@progress/kendo-react-pdf";
import { QRCodePdfElement } from "./QRCodePdfElement";

export interface QrCodePDFExportProps {
    locomotives: Locomotive[],
    reference: React.LegacyRef<PDFExport>
}

const QrCodePDFExport = (props: QrCodePDFExportProps) => {
    

    return (
        <div>
            <PDFExport
                paperSize="A4"
                ref={props.reference}
                fileName={`locomotive-qrcodes`}
                author="Schadenmeldung App"
            >
                <div
                    style={{ display: "flex", flexWrap: "wrap", textAlign: "center", justifyContent: "space-evenly", marginTop: 90}}
                >
                    {props.locomotives.map((l, i) => {
                        return (
                            <div>
                                <QRCodePdfElement id={l.id}/>
                            </div>
                        )
                    })}
                </div>
            </PDFExport>
        </div>
    )
}

export default QrCodePDFExport