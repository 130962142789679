import React from "react";
import { QRCode } from "@progress/kendo-react-barcodes";
import { generateLocUrl } from "./QrCodeUtils";

export interface QRCodePdfElementProps{
    id: string
}

const qrSize = 250

export const QRCodePdfElement = (props: QRCodePdfElementProps) => {
    return (
        <div style={{ textAlign: "center", width: qrSize, height: 350, margin: 10 }}>
            <QRCode
                value={generateLocUrl(props.id)}
                errorCorrection="H"
                size={qrSize} />
            <div>
                {props.id}
            </div>
        </div>
    );
};
