import {Html5QrcodeScanner} from 'html5-qrcode';
import * as React from 'react'
import {useEffect} from 'react';

const qrcodeRegionId = "html5qr-code-full-region";
const Html5QrcodePlugin = (props) => {

    useEffect(() => {
        // when component mounts
        const config = {fps:10, qrbox: {width: 200, height: 200}, facingMode:'environment'}
        const verbose = props.verbose === true;
        // Suceess callback is required.
        if (!(props.qrCodeSuccessCallback)) {
            throw "qrCodeSuccessCallback is required callback.";
        }
        const html5QrcodeScanner = new Html5QrcodeScanner(qrcodeRegionId, config, verbose);
        html5QrcodeScanner.render(props.qrCodeSuccessCallback, props.qrCodeErrorCallback);

        // cleanup function when component will unmount
        return () => {
            html5QrcodeScanner.clear().catch(error => {
                console.error("Failed to clear html5QrcodeScanner. ", error);
            });
        };
    }, );

    return (
        <div id={qrcodeRegionId}/>
    );
};

export default Html5QrcodePlugin;