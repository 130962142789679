import React, { useEffect, useState } from 'react';
import { Locomotive } from '../../models/Locomotive';
import { getAllLocomotives } from '../../services/apiService';
import QrCodeGrid from './QrCodeGrid';
import { Loader } from '@progress/kendo-react-indicators';

const QrCodePage = () => {
    const [locomotives, setLocomotives] = useState<Locomotive[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [isFailed, setIsFailed] = useState<boolean>(false)

    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = async () => {
        try{
            const locs = await getAllLocomotives()
            setLocomotives(locs)
            setIsLoading(false)
        }
        catch (er){
            setIsFailed(true)
            console.error(er)
        }
    }

    if(isFailed){
        return <div>Failed to get data</div>
    }

    if(isLoading){
        return <Loader size='large' style={{ position: 'absolute', left: '50%' }} />
    }

    return (
        <div>
            <QrCodeGrid locomotives={locomotives}/>
        </div>
    );
}

export default QrCodePage