import {Field, FieldValidatorType, Form, FormElement} from "@progress/kendo-react-form";
import {FormInput, FormMaskedInput, FormTextArea, FormUpload} from "./FormComponents";
import {Button} from "@progress/kendo-react-buttons";
import {DescriptionValidator, FileValidator, LokIdValidator, NameValidator} from "./Validators";
import * as React from "react";
import {useState} from "react";
import {EmailMessage, KnownEmailSendStatus} from "@azure/communication-email";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Skeleton} from "@progress/kendo-react-indicators";
import {MdQrCodeScanner} from 'react-icons/md';
import {getLocById} from "../../services/apiService";
import {Input} from "@progress/kendo-react-inputs";
import "./FormAreaDesign.css"

const {EmailClient} = require("@azure/communication-email")
const FormArea = () => {

    const [loading, setLoading] = useState(false)
    const currentUrl = new URL(window.location.href)

    const urlParams = new URLSearchParams(currentUrl.search)
    const paramValue = urlParams.get("lokid")
    const validParam = LokIdValidator(paramValue)
    const navigate = useNavigate()
    const {t} = useTranslation()

    const removeQueryParams = () => {
        const currentURL = window.location.href;
        window.location.href = currentURL.split('?')[0];
    };
    const handleQrScannerClick = () => {
        navigate("/qrreader");
    }
    const processFiles = async (uploadedFiles) => {
        const allFiles = await Promise.all(
            uploadedFiles.map(async (file) => {
                const content = await readFileAsBase64(file);
                return content;
            })
        );
        console.log(allFiles);
        return allFiles;
    };
    const readFileAsBase64 = async (fileInfo) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(fileInfo.getRawFile!());
            reader.onload = () => {
                const base64Content = reader.result?.toString().split(",")[1];
                resolve({
                    name: fileInfo.name,
                    contentType: fileInfo.extension,
                    contentInBase64: base64Content,
                });
            };
            reader.onerror = (error) => reject(error);
        });
    };
    const sendEmail = async (dataItem: { [name: string]: any }) => {

        if (dataItem["honeypot"] != null)
            throw new Error("No place for bots")

        let filesData = dataItem["attachments"]
        try {
            filesData = await processFiles(filesData);
        } catch (error) {
            console.error("Error reading files:", error);
        }

        const LokID = dataItem["lokId"]

        const response = await getLocById(LokID)
        const lokName = response.name
        const description = dataItem["description"]
        const name = dataItem["name"]

        const message: EmailMessage = {
            senderAddress: "<DoNotReply@9d9894d9-4c2e-4012-aa1e-d378131b8310.azurecomm.net>",
            content: {
                subject: "Störungsmeldung für Lok " + LokID + " namens " + lokName,
                plainText: name + " hat eine Störung für " + "\"" + lokName + "\"" + " mit ID " + "\"" + LokID + "\"" + " verfasst:" + "\n" + description
            },
            recipients: {
                to: [
                    {
                        address: "<schadenmeldung.lokomotive@rsrg.com>",
                        displayName: "Schadenmeldung Lokomotive",
                    },
                ],
                cc: [
                    {
                        address: "<philipp.cierny@rsrg.com>",
                        displayName: "Philipp Cierny"
                    },
                    {
                        address: "<nikolay.nenov@rsrg.com>",
                        displayName: "Nikolay Nenov"
                    },
                    {
                        address: "<roman.babukh@rsrg.com>",
                        displayName: "Roman Babukh"
                    }
                ]
            },
            attachments: filesData ? filesData : []
        };

        const emailClient = new EmailClient("endpoint=https://acs-rsrg-sgs-drs-increp.switzerland.communication.azure.com/;accesskey=P3PgVu3ISSAaQgkWSAxSI/QRXXKLUD18l70bRjmnf7LfLUno1z3+c3e4Zkfq0QfE8ZZD8wjKuUMFE1fq9YqDcg==");


        try {
            const poller = await emailClient.beginSend(message);
            await poller.pollUntilDone()
            const pollerResult = poller.getResult()

            if (pollerResult && pollerResult.status === KnownEmailSendStatus.Succeeded) {
                console.log(`Successfully sent the email`);
                alert(t("thankYou"));
                removeQueryParams()
            } else {
                console.error("Error sending Email: ", pollerResult?.error)
                alert(t("errorEmail"));
            }
        } catch (e) {
            console.error("Error during polling ", e);
        }
    };
    const handleSubmit = async (dataItem: { [name: string]: any }) => {
        setLoading(true)
        try {
            await sendEmail(dataItem);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
            sessionStorage.removeItem("name")
            sessionStorage.removeItem("description")
        }
    }
    const LokIdRegex: RegExp = new RegExp(/^(\d{2} \d{2} \d{4} \d{3}-\d{1})$/);

    const [error, setError] = useState(false)
    const LokValidator: FieldValidatorType = (value: any) => {
        if (!value || !LokIdRegex.test(value)) {
            return t("errorLokID")
        }
        getLocById(value)
            .then(response => {
                console.log("here")
                setError(false)
            }).catch(error => {
            console.log("here too")
            console.error("Error checking Lok ID:", error);
            setError(true)
        })
        return error ? t("invalidLokID") : ""

    }

    const [formSubmitted, setFormSubmitted] = useState(false)

    const handleNameChange = (e) =>
        sessionStorage.setItem("name", e.target.value)
    const handleDescriptionChange = (e) =>
        sessionStorage.setItem("description", e.target.value)

    return (
        <Form
            initialValues={{
                lokId: validParam ? paramValue : '',
                name: sessionStorage.getItem("name") || "",
                description: sessionStorage.getItem("description") || "",
            }}
            onSubmit={handleSubmit}
            render={(formRenderProps) => (
                <FormElement
                    style={{
                        width: "100%",
                    }}
                >
                    <fieldset className={"k-form-fieldset"}>
                        <legend className={"k-form-legend"}>
                            {loading ? t("report") : t("title")}
                        </legend>

                        {formSubmitted && Object.keys(formRenderProps.errors)
                            .filter((fieldName) => formRenderProps.errors[fieldName])
                            .length > 0 && (
                            <div className={"error-box"}>
                                <ul>
                                    {Object.keys(formRenderProps.errors)
                                        .filter((fieldName) => formRenderProps.errors[fieldName])
                                        .map((fieldName) => (
                                            <li key={fieldName}>{formRenderProps.errors[fieldName]}</li>
                                        ))}
                                </ul>
                            </div>
                        )}

                        {loading ? (
                            <Skeleton style={{height: 50}}></Skeleton>
                        ) : (
                            <div style={{display: "flex", alignItems: "flex-end"}}>
                                <div style={{flex: "1"}}>
                                    <Field
                                        name={"lokId"}
                                        id={"lokId"}
                                        label={t("lokID")}
                                        mask={"00 00 0000 000-0"}
                                        component={FormMaskedInput}
                                        validator={LokValidator}
                                        hint={t("hintLokID")}
                                    />
                                </div>
                                <Button onClick={handleQrScannerClick}>
                                    <span>
                                        <MdQrCodeScanner size={15}/>
                                    </span>

                                </Button>
                            </div>
                        )}


                        {loading ? (
                            <Skeleton style={{height: 50}}></Skeleton>
                        ) : (
                            <Field
                                name={"name"}
                                id={"name"}
                                label={t("name")}
                                value={sessionStorage.getItem("name")}
                                component={FormInput}
                                validator={NameValidator}
                                hint={t("hintName")}
                                onChange={handleNameChange}
                            />
                        )}

                        <div style={{display: "none"}}>
                            <Field
                                name={"honeypot"}
                                id={"honeypot"}
                                component={Input}
                                optional={true}
                                type={"text"}
                            />
                        </div>

                        {loading ? (
                            <Skeleton style={{height: 100}}></Skeleton>
                        ) : (<Field
                                name={"description"}
                                id={"description"}
                                label={t("description")}
                                value={sessionStorage.getItem("description")}
                                component={FormTextArea}
                                validator={DescriptionValidator}
                                hint={t("hintDescription")}
                                onChange={handleDescriptionChange}
                            />
                        )}
                        {loading ? (
                            <Skeleton style={{height: 70}}></Skeleton>
                        ) : (<Field
                                name={"attachments"}
                                id={"attachments"}
                                label={t("attachments")}
                                component={FormUpload}
                                validator={FileValidator}
                            />
                        )}

                        {loading ? (
                            <Skeleton style={{height: 60}}></Skeleton>
                        ) : (
                            <div className="k-form-buttons">
                                <Button
                                    themeColor={"primary"}
                                    type={"submit"}
                                    disabled={!formRenderProps.allowSubmit}
                                    style={{width: "100%", height: 40}}
                                    onClick={() => setFormSubmitted(true)}
                                >
                                    {t("send")}
                                </Button>
                            </div>
                        )}
                    </fieldset>
                </FormElement>
            )}
        />
    );
};

export default FormArea;