import { Locomotive } from "../models/Locomotive"
import { getApiService } from "./serviceHelpers"

export const getAllLocomotives = async (): Promise<Locomotive[]> => {
    const service = await getApiService()
    const response = await service.get<Locomotive[]>('/api/locomotive')
    const locomotives: Locomotive[] = response.data
  
    return locomotives;
  }

export const getLocById = async (id: string): Promise<Locomotive> => {
  const service = await getApiService()
  const response = await service.get<Locomotive>('api/locomotive/' + id)
  const loc = response.data

  return loc
}